/**
 * DistributorsMobilePage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';

import { Content, Benefit } from './styledComponents';

export default function DistributorsMobile() {
  return (
    <Content>
      <Helmet>
        <title>Descarga nuestra app</title>
      </Helmet>
      <h2>Tu vale en un click</h2>
      <div className="content">
        <img
          src="./images/distribuidoras-movil.jpg"
          alt="Distribuidoras móvil"
          className="app-image"
        />
        <div className="right-section">
          <h3>
            Descarga nuestra aplicación para distribuidoras, administra tu
            tiempo y negocio donde sea.
          </h3>
          <div className="available-for">Disponible para</div>
          <div className="apps-section">
            <a
              href="http://play.google.com/store/apps/details?id=mx.roket.mada.distribuidoras"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="app-img"
                alt="Google play"
                src="./images/play-store.png"
              />
            </a>
            <a
              href="https://apps.apple.com/mx/app/distribuidoras-m%C3%B3vil-corp-150/id1499742551"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="app-img"
                alt="App store"
                src="./images/app-store.png"
              />
            </a>
          </div>
          <div className="available-for">Visítanos en</div>
          <a
            className="link-distributors"
            href="https://distribuidoras.prestavale.mx/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            distribuidoras.prestavale.mx
          </a>
        </div>
      </div>
      <h2>Beneficios de la aplicación</h2>
      <div className="benefits">
        <Benefit>
          <img src="./images/logo-small.svg" alt="Logo PrestaVale pequeño" />
          Puedes otorgar vales rápido y fácil desde el lugar que necesites.
        </Benefit>
        <Benefit>
          <img src="./images/logo-small.svg" alt="Logo PrestaVale pequeño" />
          Puedes monitoriar tu límite de crédito y el de tus clientes.
        </Benefit>
        <Benefit>
          <img src="./images/logo-small.svg" alt="Logo PrestaVale pequeño" />
          Recibe notificaciones de lo que más te interesa sobre tu negocio.
        </Benefit>
        <Benefit>
          <img src="./images/logo-small.svg" alt="Logo PrestaVale pequeño" />
          Administra tu cartera.
        </Benefit>
        <Benefit>
          <img src="./images/logo-small.svg" alt="Logo PrestaVale pequeño" />
          Califica y bloquea a tus clientes.
        </Benefit>
        <Benefit>
          <img src="./images/logo-small.svg" alt="Logo PrestaVale pequeño" />
          Sube de nivel
        </Benefit>
      </div>
    </Content>
  );
}
