/**
 * HomePage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';

import MainBanner from '../../components/MainBanner';
import GainCalculator from '../../components/GainCalculator';
import Calculator from '../../components/Calculator';
import CalculatorDistributors from '../../components/CalculatorDistributors';
import Benefits from '../../components/Benefits';
import Testimonials from '../../components/Testimonials';

import {
  BenefitsSection,
  CustomCard,
  Content,
  MaxedContainer,
} from './styledComponents';
import ColoredSection from '../../components/ColoredSection';
import { Center } from '../../components/CommonElements/styledComponents';

const testimonialsList = [
  {
    name: 'Aurora',
    avatar: './images/bubbles/Aurora.png',
    text:
      'Gracias a PrestaVale puedo ser dueña de mi tiempo, obteniendo mis propios ingresos desde mi casa y así tengo tiempo de atender a mis hijos.',
    level: 'Distribuidora nivel oro',
  },
  {
    name: 'Gisela',
    avatar: './images/bubbles/Gisela.png',
    text:
      'Las comisiones en PrestaVale son muy buenas y así pude remodelar mi casa incluso me fui de vacaciones.',
    level: 'Distribuidora nivel oro',
  },
  {
    name: 'Rubí',
    avatar: './images/logo-small.svg',
    text:
      'En tiempos de crisis PrestaVale me ha apoyado para continuar obteniendo mis ganancias completas.',
    level: 'Distribuidora nivel oro',
  },
  {
    name: 'Leti',
    avatar: './images/bubbles/Leticia.png',
    text:
      'Con PrestaVale mis clientes están contentos ya que el canje de vales es práctico, así puedo tener más clientes y más ganancias.',
    level: 'Distribuidora nivel oro',
  },
];

export default function Home() {
  return (
    <Content>
      <Helmet>
        <title>Tu vale en un click</title>
      </Helmet>
      <MainBanner />
      <ColoredSection
        variant="secondary"
        header="Calculadora de quincenas"
        title="Somos tu mejor opción"
        body="Calcula cuanto ganarías según cuanto pagas a la quincena con nuestra calculadora."
        section={
          <CustomCard>
            <GainCalculator />
          </CustomCard>
        }
      />
      <Center>
        <MaxedContainer>
          <div className="centered">
            <h2>Ellas confían en nosotros</h2>
          </div>
          <Testimonials list={testimonialsList} />
        </MaxedContainer>
      </Center>
      <ColoredSection
        reversed
        variant="primary"
        header="Calculadora de vale de dinero"
        title="Tú ganas, tus clientes ganan"
        body="Ofrece a tus clientes los vales con las mejores tarifas del mercado,
        totalmente accesibles y con múltiples beneficios."
        section={
          <CustomCard>
            <CalculatorDistributors />
          </CustomCard>
        }
      />
      <BenefitsSection>
        <MaxedContainer>
          <h2 style={{ marginBottom: 32 }}>
            Múltiples beneficios para hacerte crecer
          </h2>
          <Benefits />
        </MaxedContainer>
      </BenefitsSection>
      <ColoredSection
        reversed
        variant="secondary"
        header="Calculadora de préstamo personal"
        title="Te prestamos desde el día 1"
        body="Como distribuidora puedes solicitar un préstamo personal de hasta el 10% de tu línea de crédito desde el día en que te unas a PrestaVale."
        section={
          <CustomCard>
            <Calculator />
          </CustomCard>
        }
      />
    </Content>
  );
}
