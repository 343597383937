/**
 * BeADistributorPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Axios from 'axios';
import { get } from 'lodash';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import config from '../../config';
import { Content } from './styledComponents';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BeADistributor() {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [secondLastname, setSecondLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await Axios.get(`${config.api.url}/companias`);
      setCompanies(get(response, 'data', []) || []);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleCreateProspect = async () => {
    try {
      if (Number.isNaN(phone) || phone.length < 10) {
        setErrorText('Ingrese un teléfono válido');
        return;
      }
      const body = {
        nombre: name.trim(),
        apellidoPaterno: lastname.trim(),
        apellidoMaterno: secondLastname.trim(),
        telefonoCelular: phone,
        origen: 'recomendada',
        estatus: 'porasignar',
        companiaCelularId: company,
      };
      const response = await Axios.post(
        `${config.api.url}/prospectos/crearProspectoUsuario`,
        body,
      );
      if (response && response.data) {
        setSuccessText('¡Solicitud enviada con éxito!');
        setName('');
        setLastname('');
        setSecondLastname('');
        setPhone('');
        setCompany(null);
      }
    } catch (e) {
      setErrorText('Ocurrió un error. Intente de nuevo');
    }
  };

  const disabled =
    !name ||
    !lastname ||
    !phone ||
    Number.isNaN(phone) ||
    phone.length < 10 ||
    !company;

  return (
    <Content>
      <Helmet>
        <title>Conviértete en distribuidora</title>
      </Helmet>
      <h2>Únete a nuestro equipo de distribuidoras</h2>
      <h3>
        Conviértete en miembro de la familia PrestaVale y comienza a disfrutar
        de los beneficios que tenemos para tí. Envíanos tu información y nos
        comunicaremos muy pronto contigo.
      </h3>
      <div className="form-section">
        <img
          src="./images/distribuidoras-devices.jpg"
          alt="Dispositivos distribuidoras web"
        />
        <div className="form">
          <TextField
            id="name"
            value={name}
            label="Nombre"
            variant="filled"
            onChange={e => setName(e.target.value)}
          />
          <TextField
            id="lastname"
            value={lastname}
            label="Apellido paterno"
            variant="filled"
            onChange={e => setLastname(e.target.value)}
          />
          <TextField
            id="secondLastname"
            value={secondLastname}
            label="Apellido materno"
            variant="filled"
            onChange={e => setSecondLastname(e.target.value)}
          />
          <TextField
            id="phone"
            value={phone}
            label="Número de celular"
            variant="filled"
            type="tel"
            onChange={e => setPhone(e.target.value)}
          />
          <FormControl variant="filled">
            <InputLabel id="phone-company-label">
              Compañía telefónica
            </InputLabel>
            <Select
              labelId="phone-company-label"
              id="phone-company"
              value={company}
              onChange={event => setCompany(event.target.value)}
            >
              {companies.map(c => (
                <MenuItem value={c.id} key={c.clave}>
                  {c.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={disabled}
            onClick={handleCreateProspect}
          >
            Enviar
          </Button>
        </div>
      </div>
      <Snackbar
        open={Boolean(successText)}
        autoHideDuration={6000}
        onClose={() => setSuccessText('')}
      >
        <Alert onClose={() => setSuccessText('')} severity="success">
          {successText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(errorText)}
        autoHideDuration={6000}
        onClose={() => setErrorText('')}
      >
        <Alert onClose={() => setErrorText('')} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
    </Content>
  );
}
