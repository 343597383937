/* eslint-disable no-console */
/* eslint-disable func-names */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { get, isEmpty } from 'lodash';
import Numeral from 'numeral';
import { isCreditCard } from 'validator';

import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CameraIcon from '@material-ui/icons/PhotoCamera';
import CloseIcon from '@material-ui/icons/Close';
import VideoRecorder from 'react-video-recorder';

import Loader from '../../components/Loader';
import AddressDialog from '../../components/AddressDialog';
import VideoActions from './videoActions';

import config from '../../config';
import {
  Content,
  InputFileContainer,
  VideoSection,
  NavBarContainer,
  Input,
  LoadingLabel,
  Label,
  ErrorLabel,
} from './styledComponents';

const formatAddress = address => {
  try {
    const number = address.numeroExterior ? ` #${address.numeroExterior}` : '';
    const postalCode = address.codigoPostal
      ? ` C.P. ${address.codigoPostal}`
      : '';
    const colonia = address.colonia ? ` Col. ${address.colonia}.` : '';
    const city = address.ciudad ? ` ${address.ciudad}` : '';
    const state = address.estado ? `, ${address.estado}.` : '';
    return `${address.calle ||
      ''}${number}${postalCode}${colonia}${city}${state}`;
  } catch (error) {
    return '';
  }
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TextMaskCustom = props => {
  const { inputRef, placeholder, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[4-5]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      placeholder={placeholder}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
};

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

const CanjeVale = props => {
  const token = get(props, 'match.params.token', '');
  const [card, setCard] = useState('');
  const [cardTouched, setCardTouched] = useState(false);
  const [confirmCardTouched, setConfirmCardTouched] = useState(false);
  const [confirmCard, setConfirmCard] = useState('');
  const [bank, setBank] = useState('');
  const [banksOptions, setBanksOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [cameraStarted, setCameraStarted] = useState(false);
  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [clientInfo, setClientInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingFull, setLoadingFull] = useState(true);
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [valeCanjeado, setValeCanjeado] = useState('');
  const [lastDigits, setLastDigits] = useState('');
  const [errorSelfie, setErrorSelfie] = useState('');
  const [showEditCard, setShowEditCard] = useState(true);
  const [showIdentityValidator, setShowIndentityValidator] = useState(false);
  const [alreadyUsed, setAlreadyUsed] = useState('');
  const [addressOpen, setAddressOpen] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [address, setAddress] = useState(false);
  const [addressToSave, setAddressToSave] = useState(null);
  const [videoIdentity, setVideo] = useState(null);
  const [videoURL, setVideoURL] = useState(null);
  const [videoOpen, setVideoOpen] = useState(false);
  const [showVideoCapturer, setShowVideoCapturer] = useState(false);
  const [phrase, setPhrase] = useState('');
  const [notFound, setNotFound] = useState('');

  const filesRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (fileUploaded) {
      handleValidateSelfie();
    }
  }, [fileUploaded]);

  const fetchData = async () => {
    try {
      let response = await Axios.get(`${config.api.url}/bancos`);
      const banks = get(response, 'data', []) || [];
      setBanksOptions(banks);

      response = await Axios.get(`${config.api.url}/vales`, {
        params: {
          filter: {
            where: {
              token,
            },
            include: [
              'cliente',
              'validacionesVideoFrases',
              'validacionesVideos',
            ],
          },
        },
      });

      if (!get(response, 'data[0]')) {
        setNotFound(true);
      }

      const voucherStatus = get(response, 'data[0].estatus', '');

      if (voucherStatus !== 'porcobrar') {
        if (voucherStatus === 'cancelado') {
          setAlreadyUsed('Este vale no está disponible.');
        } else {
          setAlreadyUsed(
            'Este vale ya fue canjeado. ¡Gracias por tu preferencia!',
          );
        }
        return;
      }

      const videoStatus = get(
        response,
        'data[0].validacionesVideos.estatus',
        '',
      );

      if (videoStatus === 'porvalidar') {
        setAlreadyUsed('Este vale está en proceso de validación por video.');
        return;
      }

      const clientAddress = get(response, 'data[0].cliente.domicilio', '');

      setHasAddress(!isEmpty(clientAddress));
      setAddress(clientAddress);

      const idType = !!get(
        response,
        'data[0].cliente.tipoIdentificacion',
        false,
      );

      if (!idType) setShowVideoCapturer(true);

      const estatus = get(response, 'data[0].cliente.estatus', '');

      if (estatus === 'preregistro') {
        setShowIndentityValidator(true);
      }

      const digits = get(response, 'data[0].cliente.tarjeta', '');
      if (digits) {
        setCard(digits);
        setLastDigits(`**** **** **** ${digits.substring(12)}`);
        setCardTouched(true);
      } else {
        setInputsDisabled(false);
        setShowEditCard(false);
      }

      const bankId = get(response, 'data[0].cliente.bancoId', '');
      if (bankId) {
        setBank(bankId);
      }

      const responsePhrase = get(
        response,
        'data[0].validacionesVideoFrases.texto',
        '',
      );
      if (responsePhrase) {
        setPhrase(responsePhrase);
      }
      setClientInfo(get(response, 'data[0]', {}));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingFull(false);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCanjeVale = async () => {
    try {
      setLoadingFull(true);

      setButtonDisabled(true);

      const formData = new FormData();
      const valeId = get(clientInfo, 'id', '');

      if (showIdentityValidator)
        formData.set('id', await urltoFile(fileUploaded));
      formData.set('valeId', valeId);
      formData.set('bancoId', bank);
      formData.set('clienteId', get(clientInfo, 'clienteId', ''));
      formData.set('tarjeta', card.replaceAll(' ', ''));
      formData.set('type', 'SPEI');

      if (addressToSave) {
        formData.set('calle', addressToSave.calle);
        formData.set('ciudad', addressToSave.ciudad);
        formData.set('estado', addressToSave.estado);
        formData.set('colonia', addressToSave.colonia);
        formData.set('codigoPostal', addressToSave.codigoPostal);
        formData.set('numeroExterior', addressToSave.numeroExterior);
      }

      if (videoIdentity) {
        const videoFile = new File([videoIdentity], 'validacion-video.mp4', {
          type: 'video/mp4',
        });
        formData.set('video', videoFile);
      }

      const response = await Axios.patch(
        `${config.api.url}/vales/canjearVale`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
      );

      const isSuccess =
        get(response, 'data.success', false) || get(response, 'data', false);

      if (!isSuccess) {
        setErrorText('Error canjeando vale');
        setButtonDisabled(false);
        return;
      }

      if (videoIdentity) {
        setSuccessText('Vale en proceso de autorización');
        setValeCanjeado(
          'Vale en proceso de autorización. Revisaremos tu video para canjear tu vale',
        );
      } else {
        setSuccessText('Vale canjeado con éxito');
        setValeCanjeado('Vale canjeado. ¡Gracias por tu preferencia!');
      }
    } catch (error) {
      setErrorText('Error canjeando vale');
      setButtonDisabled(false);
    } finally {
      setLoadingFull(false);
    }
  };

  const handleValidateSelfie = async () => {
    try {
      setLoadingFull(true);

      const formData = new FormData();

      const valeId = get(clientInfo, 'id', '');

      formData.set('valeId', valeId);
      formData.set('selfie', await urltoFile(fileUploaded));

      const response = await Axios.patch(
        `${config.api.url}/vales/validarCliente`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
      );

      const isSuccess = get(response, 'data.success', false);

      if (!isSuccess) {
        setErrorText('Error validando la identidad');
        setButtonDisabled(false);
        setErrorSelfie('Error validando la identidad');
        return;
      }
    } catch (error) {
      setErrorText('Error validando identidad');
      setErrorSelfie('Error validando la identidad');
    } finally {
      setLoadingFull(false);
    }
  };

  const handleChangeFiles = async () => {
    setErrorSelfie('');
    const files = Array.from(filesRef.current.files);
    if (files.some(f => f.size > 50 * 1000000)) {
      setErrorText('Foto demasiado pesada');
      return;
    }
    if (!files.length) return;
    const file = files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function() {
        setFileUploaded(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleOpenFileInput = () => {
    filesRef.current.click();
    handleCloseMenu();
  };

  const handleOpenVideo = () => {
    setVideoOpen(true);
    setVideoURL(null);
    setVideo(null);
  };

  const handleOpenCamera = () => {
    setCameraOpen(true);
    handleCloseMenu();
    setErrorSelfie('');
    setFileUploaded(null);
  };

  const handlePhotoTook = dataURI => {
    setFileUploaded(dataURI);
    setCameraOpen(false);
  };

  const handleCloseCamera = () => {
    setCameraOpen(false);
    setCameraStarted(false);
  };

  const handleCloseVideo = () => {
    setVideoOpen(false);
    setCameraStarted(false);
  };

  const handleActivateFields = () => {
    setCardTouched(false);
    setInputsDisabled(false);
    setShowIndentityValidator(true);
    if (inputsDisabled) {
      setBank('');
      setCard('');
    }
  };

  const handleSaveAddress = _address => {
    setAddressToSave(_address);
    setAddressOpen(false);
  };

  const handleStopRecording = videoBlob => {
    setVideoOpen(false);
    setVideo(videoBlob);
    setVideoURL(URL.createObjectURL(videoBlob));
  };

  const disabled =
    buttonDisabled ||
    !termsAgreed ||
    (showIdentityValidator && !fileUploaded && !videoIdentity) ||
    !isCreditCard((card || '').replaceAll(' ', '')) ||
    (!inputsDisabled && card !== confirmCard) ||
    !bank ||
    (errorSelfie && !showVideoCapturer) ||
    (isEmpty(address) && !addressToSave);

  const errorCard = cardTouched && !isCreditCard(card);
  const errorConfirmCard = confirmCardTouched && confirmCard !== card;

  return (
    <>
      <NavBarContainer>
        <Link to="/">
          <img
            className="logo"
            src="/images/logo-white.svg"
            alt="Logo PrestaVale blanco"
          />
        </Link>
      </NavBarContainer>
      <Content>
        <h1>Canje Digital Por Transferencia SPEI</h1>
        {loading && <div>Cargando...</div>}
        {!notFound && Boolean(alreadyUsed) && <div>{alreadyUsed}</div>}
        {notFound && (
          <div>
            Este vale no existe o el link es incorrecto. Contacte a su
            distribuidor
          </div>
        )}
        {!loading && !alreadyUsed && !notFound && (
          <>
            <div className="greeting">{`¡Hola, ${get(
              clientInfo,
              'cliente.nombreCompleto',
              '',
            )}!`}</div>
            <div className="introduction">
              {`Te han otorgado un vale por ${Numeral(
                clientInfo?.monto || 0,
              ).format(
                '$ 0,0.00',
              )} en un plazo de ${clientInfo?.cantidadPagos || ''} quincenas.`}
            </div>
            {Boolean(valeCanjeado) && (
              <div className="greeting">{valeCanjeado}</div>
            )}
            {!valeCanjeado && (
              <>
                <div className="instructions">
                  Para canejar el vale solo necesitamos los siguientes datos
                </div>
                <Label style={{ marginBottom: 2 }}>
                  <span style={{ marginBottom: showEditCard ? 0 : 8 }}>
                    Número de tarjeta
                  </span>
                  {showEditCard && (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleActivateFields}
                    >
                      Editar tarjeta
                    </Button>
                  )}
                </Label>
                {inputsDisabled ? (
                  <TextField
                    id="card-hidden"
                    className="input"
                    error={errorCard}
                    helperText={errorCard && 'Ingrese una tarjeta válida'}
                    value={lastDigits}
                    placeholder="Número de tarjeta"
                    variant="outlined"
                    autoFocus
                    disabled={inputsDisabled}
                  />
                ) : (
                  <>
                    <TextField
                      id="card"
                      className="input"
                      error={errorCard}
                      helperText={errorCard && 'Ingrese una tarjeta válida'}
                      value={card}
                      placeholder="Número de tarjeta"
                      variant="outlined"
                      onChange={e => setCard(e.target.value)}
                      onBlur={() => setCardTouched(true)}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        inputProps: {
                          placeholder: 'Ingresa los 16 dígitos de tu tarjeta',
                        },
                      }}
                      autoFocus
                      disabled={inputsDisabled}
                    />
                    <TextField
                      id="card"
                      className="input"
                      error={errorConfirmCard}
                      helperText={
                        errorConfirmCard && 'Las tarjetas no coinciden'
                      }
                      value={confirmCard}
                      placeholder="Confirme número de tarjeta"
                      variant="outlined"
                      onChange={e => setConfirmCard(e.target.value)}
                      onBlur={() => setConfirmCardTouched(true)}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        inputProps: {
                          placeholder: 'Confirme número de tarjeta',
                        },
                      }}
                      disabled={inputsDisabled}
                    />
                  </>
                )}
                <Label>Banco</Label>
                <FormControl variant="outlined" className="input">
                  <Select
                    labelId="bank-label"
                    id="bank"
                    value={bank}
                    onChange={event => setBank(event.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Banco' }}
                    style={{ color: bank ? '#212121' : '#a2a2a2' }}
                    disabled={inputsDisabled}
                  >
                    <MenuItem value="" disabled>
                      Banco
                    </MenuItem>
                    {banksOptions.map(c => (
                      <MenuItem value={c.id} key={c.id}>
                        {c.nombre}
                      </MenuItem>
                    ))}
                    <FormHelperText>Banco</FormHelperText>
                  </Select>
                </FormControl>
                <Label style={{ marginBottom: 10 }}>
                  <span style={{ marginBottom: showEditCard ? 0 : 8 }}>
                    Dirección
                  </span>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setAddressOpen(true)}
                  >
                    Editar dirección
                  </Button>
                </Label>
                <div style={{ marginBottom: showIdentityValidator ? 24 : 0 }}>
                  {hasAddress || addressToSave ? (
                    <>
                      <div>{formatAddress(addressToSave || address)}</div>
                    </>
                  ) : (
                    <>
                      <div style={{ marginBottom: 12 }}>
                        Debes ingresar tu dirección para canjear el vale
                      </div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setAddressOpen(true)}
                        fullWidth
                        size="large"
                      >
                        Ingresar dirección
                      </Button>
                    </>
                  )}
                </div>
                {/* eslint-disable */}
                {Boolean(videoIdentity) && (
                  <video
                    src={videoURL}
                    style={{
                      display: `${
                        videoIdentity
                          ? 'block'
                          : 'none'
                      }`,
                    }}
                    controls
                    playsInline
                  />
                )}
                {/* eslint-enable */}
                {Boolean(videoIdentity) && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleOpenVideo}
                    style={{ marginTop: 12 }}
                  >
                    Volver a grabar
                  </Button>
                )}
                {showIdentityValidator &&
                  (showVideoCapturer ? (
                    <>
                      {!videoIdentity && (
                        <VideoSection onClick={handleOpenVideo}>
                          <div className="title text">
                            Video de validación de identidad
                          </div>
                          <div className="text">
                            Para validar tu identidad es necesario tomar un
                            video con las siguientes características
                          </div>
                          <div className="text">Modo selfie</div>
                          <div className="text">
                            Tener tu INE en la mano y mostrarla a la cámara
                          </div>
                          <div className="text" style={{ marginBottom: 24 }}>
                            Leer el siguiente texto: <b>{phrase}</b>
                          </div>
                          <InputFileContainer>
                            <CameraIcon />
                            <span>Grabar video</span>
                          </InputFileContainer>
                        </VideoSection>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="label-description">
                        Toma una selfie para confirmar tu identidad
                      </div>
                      <InputFileContainer
                        error={errorSelfie}
                        onClick={handleOpenCamera}
                      >
                        {fileUploaded ? (
                          <img src={fileUploaded} alt="Img" />
                        ) : (
                          <>
                            <CameraIcon />
                            <span>Tomar una selfie</span>
                          </>
                        )}
                      </InputFileContainer>
                    </>
                  ))}
                {errorSelfie && !showVideoCapturer && (
                  <ErrorLabel>{errorSelfie}</ErrorLabel>
                )}
                {errorSelfie && !showVideoCapturer && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowVideoCapturer(true)}
                    style={{ marginTop: 12 }}
                  >
                    Intentar con video
                  </Button>
                )}
                <FormControlLabel
                  control={<Checkbox name="accept" color="primary" />}
                  label="Aceptas términos y condiciones"
                  className="accept-check"
                  onChange={event => setTermsAgreed(event.target.checked)}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleCanjeVale}
                  disabled={disabled}
                >
                  Canjear vale
                </Button>
              </>
            )}
          </>
        )}
        <Menu
          id="picture-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleOpenFileInput}>Galería</MenuItem>
          <MenuItem onClick={handleOpenCamera}>Cámara</MenuItem>
        </Menu>
        <Input
          ref={filesRef}
          type="file"
          value=""
          onChange={handleChangeFiles}
          accept=".png, .jpg, .jpeg"
        />
        <Dialog open={cameraOpen} onClose={handleCloseCamera} maxWidth="sm">
          <IconButton
            style={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}
            onClick={handleCloseCamera}
          >
            <CloseIcon />
          </IconButton>
          {!cameraStarted && <LoadingLabel>Abriendo cámara...</LoadingLabel>}
          <Camera
            onTakePhoto={handlePhotoTook}
            onCameraStart={() => {
              setCameraStarted(true);
            }}
          />
        </Dialog>
        <Dialog open={videoOpen} onClose={handleCloseVideo} maxWidth="sm">
          <IconButton
            style={{ position: 'absolute', top: 16, right: 16, zIndex: 10 }}
            onClick={handleCloseVideo}
          >
            <CloseIcon />
          </IconButton>
          <div
            style={{
              width: 600,
              height: 400,
              maxHeight: '100%',
              maxWidth: '100%',
            }}
          >
            <VideoRecorder
              constraints={{
                audio: true,
                video: {
                  facingMode: 'user',
                },
              }}
              onRecordingComplete={handleStopRecording}
              isOnInitially
              renderActions={videoProps => (
                <VideoActions {...videoProps} phrase={phrase} />
              )}
              timeLimit={30 * 1000}
            />
          </div>
        </Dialog>
      </Content>
      <Snackbar
        open={Boolean(successText)}
        autoHideDuration={6000}
        onClose={() => setSuccessText('')}
      >
        <Alert onClose={() => setSuccessText('')} severity="success">
          {successText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(errorText)}
        autoHideDuration={6000}
        onClose={() => setErrorText('')}
      >
        <Alert onClose={() => setErrorText('')} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
      <AddressDialog
        open={addressOpen}
        onClose={() => setAddressOpen(false)}
        onSaveAddress={handleSaveAddress}
      />
      {loadingFull && <Loader />}
    </>
  );
};

export default CanjeVale;
