/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable func-names */
import React, { useState, useRef, useEffect } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Camera from 'react-html5-camera-photo';
import VideoRecorder from 'react-video-recorder';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { get, isEmpty } from 'lodash';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Numeral from 'numeral';
import parse from 'html-react-parser';
import VideoActions from './videoActions';

import Loader from '../../components/Loader';

import config from '../../config';

import {
  Content,
  ContainerCard,
  NavBarContainer,
  VideoSection,
  InputFileContainer,
  Input,
  LoadingLabel,
  ContainerGeneral,
  ContentButonGroup,
  ContentButon,
  ContentDialog,
  ContainerData,
  ButtonGrupDialog,
  ContentTerms,
  ContainerDialogTerms,
} from './styledComponents';

const numberRegex = /^\d+$/;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options = {
  // eslint-disable-next-line consistent-return
  replace: domNode => {
    if (domNode.attribs && domNode.attribs.class === 'remove') {
      return <></>;
    }
  },
};

const TextMaskCustom = props => {
  const { inputRef, placeholder, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[4-5]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      placeholder={placeholder}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
};

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

const CanjeVentanilla = props => {
  const [activeStep, setActiveStep] = React.useState(0);

  const [cancelVaucher, setCancelVaucher] = useState(false);

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [errorSelfie, setErrorSelfie] = useState('');
  const [cameraOpen, setCameraOpen] = useState(false);
  const [termsAgreedOpen, setTermsAgreedOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [cameraStarted, setCameraStarted] = useState(false);

  const [videoIdentity, setVideo] = useState(null);
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoURL, setVideoURL] = useState(null);

  const [notFound, setNotFound] = useState('');
  const [loading, setLoading] = useState(true);

  const [phrase, setPhrase] = useState('');
  const [errorText, setErrorText] = useState('');
  const token = get(props, 'match.params.token', '');
  const [alreadyUsed, setAlreadyUsed] = useState('');

  const [clientInfo, setClientInfo] = useState(null);
  const [loadingFull, setLoadingFull] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [valeCanjeado, setValeCanjeado] = useState('');

  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [suburb, setSuburb] = useState('');
  const [suburbsOptions, setSuburbsOptions] = useState([]);
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');

  const [showIdentityValidator, setShowIndentityValidator] = useState(false);
  const [showVideoCapturer, setShowVideoCapturer] = useState(false);

  const [voucherRedeemed, setVaucherRedeemed] = useState(false);

  const [newPostalCode, setNewPostalCode] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newSuburb, setNewSuburb] = useState('');
  const [newStreet, setNewStreet] = useState('');
  const [newNumber, setNewNumber] = useState('');

  const [startExchange, setStartExchange] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [existAddress, setExistAddress] = useState(false);

  const [confirmCancelVaucher, setConfirmCancelVaucher] = useState(false);

  useEffect(() => {
    if (newPostalCode.length === 5) {
      fetchDataAddress();
    }
  }, [newPostalCode]);

  useEffect(() => {
    if (fileUploaded) {
      handleValidateSelfie();
    }
  }, [fileUploaded]);

  useEffect(() => {
    if (editAddress === false && activeStep === 0) {
      setPostalCode(get(clientInfo, 'cliente.domicilio.codigoPostal', ''));
      setCity(get(clientInfo, 'cliente.domicilio.ciudad', ''));
      setState(get(clientInfo, 'cliente.domicilio.estado', ''));
      setSuburb(get(clientInfo, 'cliente.domicilio.colonia', ''));
      setStreet(get(clientInfo, 'cliente.domicilio.calle', ''));
      setNumber(get(clientInfo, 'cliente.domicilio.numeroExterior', ''));
    }
  }, [
    setPostalCode,
    setCity,
    setState,
    setSuburb,
    setStreet,
    setNumber,
    editAddress,
    activeStep,
    clientInfo,
  ]);

  const handleOpenCancelVaocuher = () => {
    setCancelVaucher(true);
  };

  const handleCloseCancelVaucher = () => {
    setCancelVaucher(false);
  };

  const handleCancelVaucher = async () => {
    try {
      setLoading(true);
      const body = {
        token,
      };
      await Axios.post(`${config.api.url}/vales/cancelar-vale`, body);

      setCancelVaucher(false);
      setConfirmCancelVaucher(true);
    } finally {
      setLoading(false);
    }
  };

  const start = () => {
    setStartExchange(true);
  };

  const fetchDataAddress = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(`${config.api.url}/colonias`, {
        params: {
          filter: {
            where: {
              codigoPostal: newPostalCode,
            },
          },
        },
      });
      const items = response?.data || [];
      const [firstItem] = items;
      if (!firstItem) return;
      const { nomMunicipio, nomEstado, nomColonia } = firstItem;
      setNewCity(nomMunicipio);
      setNewState(nomEstado);
      setNewSuburb(nomColonia);
      setSuburbsOptions(items);
    } finally {
      setLoading(false);
    }
  };

  const handleCanjeVale = async () => {
    try {
      setLoadingFull(true);

      setButtonDisabled(true);

      const formData = new FormData();
      const valeId = get(clientInfo, 'id', '');

      if (showIdentityValidator)
        formData.set('id', await urltoFile(fileUploaded));
      formData.set('valeId', valeId);
      formData.set('clienteId', get(clientInfo, 'clienteId', ''));
      formData.set('type', 'VENTANILLA');

      if (editAddress === true) {
        formData.set('calle', newStreet);
        formData.set('ciudad', newCity);
        formData.set('estado', newState);
        formData.set('colonia', newSuburb);
        formData.set('codigoPostal', newPostalCode);
        formData.set('numeroExterior', newNumber);
      }

      if (editAddress === false) {
        formData.set('calle', street);
        formData.set('ciudad', city);
        formData.set('estado', state);
        formData.set('colonia', suburb);
        formData.set('codigoPostal', postalCode);
        formData.set('numeroExterior', number);
      }

      if (videoIdentity) {
        const videoFile = new File([videoIdentity], 'validacion-video.mp4', {
          type: 'video/mp4',
        });
        formData.set('video', videoFile);
      }

      const response = await Axios.patch(
        `${config.api.url}/vales/canjearVale`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
      );

      const isSuccess =
        get(response, 'data.success', false) || get(response, 'data', false);

      if (!isSuccess) {
        setErrorText('Error canjeando vale');
        setButtonDisabled(false);
        return;
      }

      if (videoIdentity) {
        setSuccessText('Vale en proceso de autorización');
        setValeCanjeado(
          'Vale en proceso de autorización. Revisaremos tu video para canjear tu vale',
        );
      } else {
        setSuccessText('Vale canjeado con éxito');
        setValeCanjeado('Vale canjeado. ¡Gracias por tu preferencia!');
      }
    } catch (error) {
      setErrorText('Error canjeando vale');
      setButtonDisabled(false);
    } finally {
      setLoadingFull(false);
      setVaucherRedeemed(true);
    }
  };

  const filesRef = useRef(null);

  useEffect(() => {
    fetchData();
    fetchConfig();
  }, []);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    if (activeStep === 1) {
      setTermsAgreed(false);
    }
  };

  const handleOpenCamera = () => {
    setCameraOpen(true);
    handleCloseMenu();
  };

  const handleTermsAgreedOpen = () => {
    setTermsAgreedOpen(true);
  };

  const handleTermsAgreedClose = () => {
    setTermsAgreedOpen(false);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseCamera = () => {
    setCameraOpen(false);
    setCameraStarted(false);
  };

  const handlePhotoTook = dataURI => {
    setFileUploaded(dataURI);
    setCameraOpen(false);
  };

  const handleOpenVideo = () => {
    setVideoOpen(true);
    setVideoURL(null);
    setVideo(null);
  };

  const handleCloseVideo = () => {
    setVideoOpen(false);
    setCameraStarted(false);
  };

  const handleOpenFileInput = () => {
    filesRef.current.click();
    handleCloseMenu();
  };

  const handleStopRecording = videoBlob => {
    setVideoOpen(false);
    setVideo(videoBlob);
    setVideoURL(URL.createObjectURL(videoBlob));
  };

  const handleValidateSelfie = async () => {
    try {
      setLoadingFull(true);

      const formData = new FormData();

      const valeId = get(clientInfo, 'id', '');

      formData.set('valeId', valeId);
      formData.set('selfie', await urltoFile(fileUploaded));

      const response = await Axios.patch(
        `${config.api.url}/vales/validarCliente`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
      );

      const isSuccess = get(response, 'data.success', false);

      if (!isSuccess) {
        setErrorText('Error validando la identidad');
        setButtonDisabled(false);
        setErrorSelfie('Error validando la identidad');
        return;
      }
    } catch (error) {
      setErrorText('Error validando identidad');
      setErrorSelfie('Error validando la identidad');
    } finally {
      setLoadingFull(false);
    }
  };

  const handleChangeFiles = async () => {
    setErrorSelfie('');
    const files = Array.from(filesRef.current.files);
    if (files.some(f => f.size > 50 * 1000000)) {
      setErrorText('Foto demasiado pesada');
      return;
    }
    if (!files.length) return;
    const file = files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function() {
        setFileUploaded(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const editAddressStatus = e => {
    setEditAddress(e);
    if (editAddress === true) {
      fetchData();
    }
  };

  const fetchConfig = async () => {
    try {
      const response = await Axios.get(`${config.api.url}/configuraciones`);
      setTermsAndConditions(get(response, 'data[0].terminosCondiciones', ''));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await Axios.get(`${config.api.url}/vales`, {
        params: {
          filter: {
            where: {
              token,
            },
            include: [
              'cliente',
              'validacionesVideoFrases',
              'validacionesVideos',
            ],
          },
        },
      });

      if (!get(response, 'data[0]')) {
        setNotFound(true);
      }

      const voucherStatus = get(response, 'data[0].estatus', '');

      if (voucherStatus !== 'porcobrar') {
        if (voucherStatus === 'cancelado') {
          setAlreadyUsed(
            'Este vale ha sido cancelado, por favor contacta a tu mayorista para más información.',
          );
        } else {
          setAlreadyUsed(
            'Este vale ya fue canjeado. ¡Gracias por tu preferencia!',
          );
        }
        return;
      }

      const videoStatus = get(
        response,
        'data[0].validacionesVideos.estatus',
        '',
      );

      if (videoStatus === 'porvalidar') {
        setAlreadyUsed('Este vale está en proceso de validación por video.');
        return;
      }

      const idType = !!get(
        response,
        'data[0].cliente.tipoIdentificacion',
        false,
      );

      if (!idType) setShowVideoCapturer(true);

      const estatus = get(response, 'data[0].cliente.estatus', '');

      if (estatus === 'preregistro') {
        setShowIndentityValidator(true);
      }

      const responsePhrase = get(
        response,
        'data[0].validacionesVideoFrases.texto',
        '',
      );
      if (responsePhrase) {
        setPhrase(responsePhrase);
      }
      setClientInfo(get(response, 'data[0]', {}));
      setExistAddress(get(response, 'data[0].cliente.domicilio', {}));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingFull(false);
    }
  };

  const disabled =
    buttonDisabled ||
    !termsAgreed ||
    (showIdentityValidator && !fileUploaded && !videoIdentity) ||
    (errorSelfie && !showVideoCapturer);

  return (
    <ContainerGeneral>
      <NavBarContainer>
        <Link to="/">
          <img
            className="logo"
            src="/images/logo-white.svg"
            alt="Logo PrestaVale blanco"
          />
        </Link>
      </NavBarContainer>
      {startExchange === false && (
        <>
          {loading && <Loader />}
          {!notFound && Boolean(alreadyUsed) && (
            <>
              <Content>
                <h1>Canje digital por retiro en ventanilla</h1>
                <div>{alreadyUsed}</div>
              </Content>
            </>
          )}
          {notFound && (
            <Content>
              <div>
                Este vale no existe o el link es incorrecto. Contacte a su
                distribuidor.
              </div>
            </Content>
          )}
          {!loading && !alreadyUsed && !notFound && (
            <>
              <Content>
                <h1>Canje digital por retiro en ventanilla</h1>
                {confirmCancelVaucher === false && (
                  <>
                    <div className="firstLabel">{`¡Hola, ${get(
                      clientInfo,
                      'cliente.nombreCompleto',
                      '',
                    )}!`}</div>
                    <ContainerData>
                      <h3>Iniciaras tu canje de vale por</h3>
                      <div className="number">
                        {Numeral(clientInfo?.monto || 0).format('$ 0,0.00')}
                      </div>
                    </ContainerData>
                    <ContainerData>
                      <h3>Plazo: </h3>
                      <div className="numbertwo">{`${clientInfo?.cantidadPagos ||
                        ''} quincenas`}</div>
                    </ContainerData>
                    <ContainerData>
                      <h3>Pago quincenal: </h3>
                      <div className="numbertwo">
                        {Numeral(clientInfo?.pagoQuincenal || 0).format(
                          '$ 0,0.00',
                        )}
                      </div>
                    </ContainerData>

                    <ContentButonGroup>
                      <ContentButon>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={start}
                        >
                          Comenzar canje
                        </Button>
                      </ContentButon>
                      <ContentButon>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={handleOpenCancelVaocuher}
                        >
                          No quiero el vale
                        </Button>
                      </ContentButon>
                    </ContentButonGroup>
                  </>
                )}

                {confirmCancelVaucher === true && (
                  <>
                    <div className="greeting">Vale cancelado</div>
                    <h2>{`El vale con el folio: ${get(
                      clientInfo,
                      'folio',
                      '',
                    )} ha sido cancelado exitosamente.`}</h2>
                  </>
                )}

                <Dialog
                  open={cancelVaucher}
                  onClose={handleCloseCancelVaucher}
                  maxWidth="sm"
                >
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      zIndex: 10,
                    }}
                    onClick={handleCloseCancelVaucher}
                  >
                    <CloseIcon />
                  </IconButton>
                  <ContentDialog>
                    <h1>¿Estás seguro de que no quieres el vale?</h1>
                    <h2>Al rechazarlo se cancelará y no podrás canjearlo</h2>
                    <ButtonGrupDialog>
                      <Button
                        color="primary"
                        onClick={handleCloseCancelVaucher}
                        style={{ textTransform: 'none', marginRight: 15 }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCancelVaucher}
                        style={{ textTransform: 'none' }}
                      >
                        Aceptar
                      </Button>
                    </ButtonGrupDialog>
                  </ContentDialog>
                </Dialog>
              </Content>
            </>
          )}
        </>
      )}

      {startExchange && (
        <>
          {activeStep === 0 ? (
            <>
              {(existAddress === false || editAddress === true) && (
                <>
                  <Content>
                    <h1>Canje digital por retiro en ventanilla</h1>
                    <ContainerCard>
                      <div className="greeting">Dirección</div>
                      {existAddress !== false && (
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => editAddressStatus(false)}
                        >
                          Cancelar
                        </Button>
                      )}
                    </ContainerCard>
                    <h2>Codigo postal</h2>
                    <TextField
                      id="postal-code"
                      value={newPostalCode}
                      variant="outlined"
                      onChange={e => {
                        const { value } = e.target;
                        if (!value || numberRegex.test(value)) {
                          setNewPostalCode(value);
                        }
                      }}
                      fullWidth
                      inputProps={{ maxLength: 5 }}
                    />

                    <h2>Ciudad</h2>
                    <TextField
                      id="city"
                      value={newCity}
                      onChange={e => setNewCity(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <h2>Estado</h2>
                    <TextField
                      id="state"
                      value={newState}
                      onChange={e => setNewState(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />

                    <h2>Colonia</h2>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        labelId="suburb-label"
                        id="suburb"
                        value={newSuburb}
                        onChange={event => setNewSuburb(event.target.value)}
                      >
                        {suburbsOptions.map(c => (
                          <MenuItem value={c.nomColonia} key={c.id}>
                            {c.nomColonia}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <h2>Calle</h2>
                    <TextField
                      id="street"
                      value={newStreet}
                      variant="outlined"
                      onChange={e => setNewStreet(e.target.value)}
                      fullWidth
                    />

                    <h2>Número exterior</h2>
                    <TextField
                      id="number"
                      value={newNumber}
                      variant="outlined"
                      onChange={e => {
                        const { value } = e.target;
                        if (!value || numberRegex.test(value)) {
                          setNewNumber(value);
                        }
                      }}
                      fullWidth
                      inputProps={{ maxLength: 5 }}
                    />
                  </Content>
                </>
              )}
              {existAddress !== false && editAddress === false && (
                <Content>
                  <h1>Canje digital por retiro en ventanilla</h1>
                  <ContainerCard>
                    <div className="greeting">Dirección</div>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => editAddressStatus(true)}
                    >
                      Editar dirección
                    </Button>
                  </ContainerCard>
                  <div>
                    {`${get(clientInfo, 'cliente.domicilio.calle', '')} `}
                    {`#${get(
                      clientInfo,
                      'cliente.domicilio.numeroExterior',
                      '',
                    )} `}
                    {`C.P. ${get(
                      clientInfo,
                      'cliente.domicilio.codigoPostal',
                      '',
                    )} `}
                    {`Col. ${get(
                      clientInfo,
                      'cliente.domicilio.colonia',
                      '',
                    )}. `}
                    {`${get(clientInfo, 'cliente.domicilio.ciudad', '')}, `}
                    {`${get(clientInfo, 'cliente.domicilio.estado', '')}.`}
                  </div>
                </Content>
              )}
            </>
          ) : null}
        </>
      )}

      {activeStep === 1 && voucherRedeemed === false ? (
        <Content>
          <h1>Canje digital por retiro en ventanilla</h1>
          <div className="greeting">Validación de identidad</div>

          {/* eslint-disable */}
          {Boolean(videoIdentity) && (
            <>
              <video
                src={videoURL}
                style={{
                  display: `${videoIdentity ? 'block' : 'none'}`,
                }}
                controls
                playsInline
              />
            </>
          )}
          {/* eslint-enable */}
          {Boolean(videoIdentity) && (
            <Button
              variant="text"
              color="primary"
              onClick={handleOpenVideo}
              style={{ marginTop: 12 }}
            >
              Volver a grabar
            </Button>
          )}
          {showIdentityValidator &&
            (showVideoCapturer ? (
              <>
                {!videoIdentity && (
                  <VideoSection onClick={handleOpenVideo}>
                    <div className="title text">
                      Video de validación de identidad
                    </div>
                    <div className="text">
                      Para validar tu identidad es necesario tomar un video con
                      las siguientes características
                    </div>
                    <div className="text">Modo selfie</div>
                    <div className="text">
                      Tener tu INE en la mano y mostrarla a la cámara
                    </div>
                    <div className="text" style={{ marginBottom: 24 }}>
                      Leer el siguiente texto: <b>{phrase}</b>
                    </div>
                    <InputFileContainer>
                      <CameraIcon />
                      <span>Grabar video</span>
                    </InputFileContainer>
                  </VideoSection>
                )}
              </>
            ) : (
              <>
                <div className="label-description">
                  Toma una selfie para confirmar tu identidad
                </div>
                <InputFileContainer
                  error={errorSelfie}
                  onClick={handleOpenCamera}
                >
                  {fileUploaded ? (
                    <img src={fileUploaded} alt="Img" />
                  ) : (
                    <>
                      <CameraIcon />
                      <span>Tomar una selfie</span>
                    </>
                  )}
                </InputFileContainer>
              </>
            ))}
          {errorSelfie && !showVideoCapturer && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowVideoCapturer(true)}
              style={{ marginTop: 12 }}
            >
              Intentar con video
            </Button>
          )}
          <ContentTerms>
            <FormControlLabel
              control={<Checkbox name="accept" color="primary" />}
              // label="Aceptas términos y condiciones."
              className="accept-check"
              onChange={event => setTermsAgreed(event.target.checked)}
            />
            <Button onClick={handleTermsAgreedOpen}>
              Aceptas términos y condiciones.
            </Button>
          </ContentTerms>

          <Menu
            id="picture-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleOpenFileInput}>Galería</MenuItem>
            <MenuItem onClick={handleOpenCamera}>Cámara</MenuItem>
          </Menu>
          <Input
            ref={filesRef}
            type="file"
            value=""
            onChange={handleChangeFiles}
            accept=".png, .jpg, .jpeg"
          />
          <Dialog open={cameraOpen} onClose={handleCloseCamera} maxWidth="sm">
            <IconButton
              style={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}
              onClick={handleCloseCamera}
            >
              <CloseIcon />
            </IconButton>
            {!cameraStarted && <LoadingLabel>Abriendo cámara...</LoadingLabel>}
            <Camera
              onTakePhoto={handlePhotoTook}
              onCameraStart={() => {
                setCameraStarted(true);
              }}
            />
          </Dialog>

          <Snackbar
            open={Boolean(successText)}
            autoHideDuration={6000}
            onClose={() => setSuccessText('')}
          >
            <Alert onClose={() => setSuccessText('')} severity="success">
              {successText}
            </Alert>
          </Snackbar>
          <Snackbar
            open={Boolean(errorText)}
            autoHideDuration={6000}
            onClose={() => setErrorText('')}
          >
            <Alert onClose={() => setErrorText('')} severity="error">
              {errorText}
            </Alert>
          </Snackbar>

          <Dialog open={videoOpen} onClose={handleCloseVideo} maxWidth="sm">
            <IconButton
              style={{ position: 'absolute', top: 16, right: 16, zIndex: 10 }}
              onClick={handleCloseVideo}
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                width: 600,
                height: 400,
                maxHeight: '100%',
                maxWidth: '100%',
              }}
            >
              <VideoRecorder
                constraints={{
                  audio: true,
                  video: {
                    facingMode: 'user',
                  },
                }}
                onRecordingComplete={handleStopRecording}
                isOnInitially
                renderActions={videoProps => (
                  <VideoActions {...videoProps} phrase={phrase} />
                )}
                timeLimit={30 * 1000}
              />
            </div>
          </Dialog>
        </Content>
      ) : null}

      {voucherRedeemed === true && (
        <Content>
          <h1>Canje digital por retiro en ventanilla</h1>
          <div className="greeting">{`Listo, ${get(
            clientInfo,
            'cliente.nombreCompleto',
            '',
          )}!`}</div>
          <div className="instructions">{valeCanjeado}</div>
        </Content>
      )}

      {startExchange && (
        <>
          {voucherRedeemed === false && (
            <MobileStepper
              variant="dots"
              steps={2}
              activeStep={activeStep}
              nextButton={
                <>
                  {activeStep === 1 ? (
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={handleCanjeVale}
                      disabled={disabled}
                      style={{ textTransform: 'none' }}
                    >
                      Canjear
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      onClick={handleNext}
                      color="primary"
                      disabled={
                        activeStep === 1 ||
                        (editAddress === true && isEmpty(newPostalCode)) ||
                        (editAddress === true && isEmpty(newCity)) ||
                        (editAddress === true && isEmpty(newState)) ||
                        (editAddress === true && isEmpty(newSuburb)) ||
                        (editAddress === true && isEmpty(newStreet)) ||
                        (editAddress === true && isEmpty(newNumber))
                      }
                      style={{ textTransform: 'none' }}
                    >
                      Siguiente
                    </Button>
                  )}
                </>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  color="primary"
                  disabled={activeStep === 0}
                  style={{ textTransform: 'none' }}
                >
                  Atrás
                </Button>
              }
            />
          )}
        </>
      )}

      {loadingFull && <Loader />}
      <Dialog
        open={termsAgreedOpen}
        onClose={handleTermsAgreedClose}
        maxWidth="sm"
      >
        <IconButton
          style={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}
          onClick={handleTermsAgreedClose}
        >
          <CloseIcon />
        </IconButton>
        <ContainerDialogTerms>
          <div>{parse(termsAndConditions, options)}</div>
        </ContainerDialogTerms>
      </Dialog>
    </ContainerGeneral>
  );
};

export default CanjeVentanilla;
