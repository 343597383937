/* eslint-disable func-names */
import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

LogRocket.init('b2gejo/prestavale-core');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
  document.addEventListener(
    'copy',
    function(event) {
      event.clipboardData.setData(
        'text/plain',
        'No se permite copiar en esta página web',
      );
      event.preventDefault();
    },
    false,
  ),
  document.addEventListener(
    'paste',
    function(event) {
      event.clipboardData.setData(
        'text/plain',
        'No se permite pegar en esta página web',
      );
      event.preventDefault();
    },
    false,
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
