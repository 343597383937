import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Axios from 'axios';
import { get } from 'lodash';
import parse from 'html-react-parser';
import config from '../../config';
import { Content, ContainerDialogTerms } from './styledComponents';
import Loader from '../../components/Loader';

const options = {
  // eslint-disable-next-line consistent-return
  replace: domNode => {
    if (domNode.attribs && domNode.attribs.class === 'remove') {
      return <></>;
    }
  },
};

export default function AvisoPrivacidad() {
  const [loadingFull, setLoadingFull] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState('');

  const fetchConfig = async () => {
    try {
      setLoadingFull(true);
      const response = await Axios.get(`${config.api.url}/configuraciones`);
      setTermsAndConditions(get(response, 'data[0].terminosCondiciones', ''));
    } catch (error) {
      console.error(error); // eslint-disable-line
    } finally {
      setLoadingFull(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <Content>
      <Helmet>
        <title>Aviso de privacidad</title>
      </Helmet>
      <ContainerDialogTerms>
        <div>{parse(termsAndConditions, options)}</div>
      </ContainerDialogTerms>
      {loadingFull && <Loader />}
    </Content>
  );
}
