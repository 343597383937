/**
 * GainCalculatorPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React, { useState } from 'react';
import Numeral from 'numeral';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Chart from 'react-google-charts';

import Slider from '../Slider';

import { Content } from './styledComponents';
import { media } from '../../helper';

export default function GainCalculator() {
  const [value, setValue] = useState(10);
  const matchesXs = useMediaQuery(media.medium);

  const realValue = value * 1000;
  const prestavaleValue = realValue * 0.18;
  const others1Value = realValue * 0.16;
  const others2Value = realValue * 0.12;
  const formatedPrestavaleValue = `${matchesXs ? 'PrestaVale   ' : ''}${Numeral(
    prestavaleValue,
  ).format('$ 0,0')}`;
  const formatedOthers1Value = `${matchesXs ? 'Otros   ' : ''}${Numeral(
    others1Value,
  ).format('$ 0,0')}`;
  const formatedOthers2Value = `${matchesXs ? 'Otros   ' : ''}${Numeral(
    others2Value,
  ).format('$ 0,0')}`;

  const tenPercent = realValue * 0.1;
  const twentyPercent = realValue * 0.2;

  return (
    <Content>
      <div className="slider-section">
        <div className="amount">{Numeral(realValue).format('$ 0,0')}</div>
        <h4>¿Cuánto pagas a la quincena?</h4>
        <Slider value={value} onChange={(e, _value) => setValue(_value)} />
        <div className="drag-section">Arrastra para calcular</div>
      </div>
      <div className="graph-section">
        <h4>Esto ganarías</h4>
        <div className="chart-container">
          <div className="logo-graph-container">
            <img
              src={
                matchesXs ? './images/logo-small.svg' : './images/logo-gray.svg'
              }
              alt="Logo PrestaVale"
              className="logo-graph"
            />
            <div>OTROS</div>
            <div>OTROS</div>
          </div>
          <div>
            <Chart
              width="320px"
              height="400px"
              chartType="BarChart"
              loader={<div>Cargando...</div>}
              data={[
                ['', '', { role: 'style' }, { role: 'annotation' }],
                [
                  'PrestaVale',
                  prestavaleValue,
                  '#00843D',
                  formatedPrestavaleValue,
                ],
                ['Otros', others1Value, '#D6D5D5', formatedOthers1Value],
                ['Otros', others2Value, '#D6D5D5', formatedOthers2Value],
              ]}
              options={{
                title: '',
                chartArea: { width: '100%' },
                hAxis: {
                  title: '',
                  minValue: 0,
                  format: 'percent',
                  ticks: [
                    { v: tenPercent, f: '10%' },
                    { v: twentyPercent, f: '20%' },
                  ],
                },
                vAxis: {
                  title: '',
                },
                annotations: {
                  textStyle: {
                    fontSize: 18,
                    color: '#ffffff',
                    fontFamily: 'Avenir',
                  },
                },
              }}
              // For tests
              rootProps={{ 'data-testid': '1' }}
            />
          </div>
        </div>
      </div>
    </Content>
  );
}
